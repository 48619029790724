<template>
  <v-container style="max-width: 1400px">
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            <span class=""> 대시보드 </span>
          </v-card-title>
          <v-card-subtitle> 메이드올 근황 통계 </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card elevation="4" rounded="lg">
          <v-card-title
            class="subtitle-1 font-weight-bold"
            v-text="'회원 수'"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card elevation="4" rounded="lg">
          <v-card-title>
            <span
              class="subtitle-1 font-weight-bold"
              v-text="'네이버 쇼핑 순위'"
            />
            <v-spacer />
            <v-btn icon small @click="addProductDialog = true" class="mr-2">
              <feather type="plus-square" />
            </v-btn>
            <v-dialog v-model="addProductDialog" width="320px">
              <v-card class="py-2" rounded="lg">
                <v-card-title> 제품 추가 </v-card-title>
                <v-card-text>
                  <v-text-field dense hide-details v-model="addProductValue" />
                </v-card-text>
                <v-card-actions>
                  <v-btn block color="primary" @click="addProduct">
                    추가
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn icon small @click="refreshSearch()">
              <v-icon v-if="!refreshSearchLoading"> mdi-refresh </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                size="18"
                color="primary"
                width="3"
              />
            </v-btn>
          </v-card-title>
          <v-card-text class="d-flex">
            <v-expansion-panels multiple v-if="!rankDataLoading">
              <v-expansion-panel
                v-for="product in rankData.list"
                :key="product"
              >
                <v-expansion-panel-header>
                  <span
                    class="subtitle-2 font-weight-regular onSurface--text"
                    v-text="rankData && rankData.item[0][product][0].keyWord"
                  />
                  <v-spacer />
                  <div class="text-end">
                    <span
                      class="sutitle-2 font-weight-regular grey--text text-end mr-2"
                      v-text="
                        rankData.item[6][product]
                          ? rankData.item[6][product][0].rank + '위'
                          : '-위'
                      "
                    />
                    <span
                      class="sutitle-2 font-weight-regular grey--text text-end mr-2"
                      v-text="
                        rankData.item[1][product]
                          ? rankData.item[1][product][0].rank + '위'
                          : '-위'
                      "
                    />
                    <span
                      class="sutitle-2 font-weight-regular onSurface--text text-end mr-5"
                      v-text="
                        rankData && rankData.item[0][product][0].rank + '위'
                      "
                    />
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="d-flex"
                    v-for="item in rankData.item[0][product]"
                    :key="item.id"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                      v-text="rankData && item.keyWord"
                    />
                    <v-spacer />
                    <span
                      class="sutitle-2 font-weight-regular grey--text text-end mr-2"
                      v-text="
                        rankData.item[6][product]
                          ? rankData.item[6][product].findIndex(
                              (v) => v.keyWord == item.keyWord,
                            ) != -1
                            ? rankData.item[6][product][
                                rankData.item[6][product].findIndex(
                                  (v) => v.keyWord == item.keyWord,
                                )
                              ].rank + '위'
                            : '-위'
                          : '-위'
                      "
                    />
                    <span
                      class="sutitle-2 font-weight-regular grey--text text-end mr-2"
                      v-text="
                        rankData.item[1][product]
                          ? rankData.item[1][product].findIndex(
                              (v) => v.keyWord == item.keyWord,
                            ) != -1
                            ? rankData.item[1][product][
                                rankData.item[1][product].findIndex(
                                  (v) => v.keyWord == item.keyWord,
                                )
                              ].rank + '위'
                            : '-위'
                          : '-위'
                      "
                    />
                    <span
                      class="sutitle-2 font-weight-regular onSurface--text"
                      v-text="rankData && item.rank + '위'"
                    />
                  </div>
                  <v-btn
                    block
                    depressed
                    small
                    color="transparent"
                    class="my-auto mt-2"
                    @click="addKeyWord(product)"
                  >
                    <feather type="plus-square" size="18" class="mr-2" /> 키워드
                    추가
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-progress-circular
              indeterminate
              v-else
              class="mx-auto my-5"
              color="primary"
            />
            <admin-dashboard-smart-store-dialog
              :product="product"
              :data="rankData"
              :dialog="addKeyWordDialog"
              @closeDialog="closeDialog"
              @refreshSearch="refreshSearch"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
import AdminDashboardSmartStoreDialog from "../../components/Dialog/Admin/AdminDashboardSmartStoreDialog.vue";

export default {
  components: { AdminDashboardSmartStoreDialog },
  name: "Dashboard",
  data() {
    return {
      refreshSearchLoading: false,
      rankData: { list: [], item: [] },
      product: "",
      rankDataLoading: true,
      addKeyWordDialog: false,
      addProductValue: "",
      addProductDialog: false,
    };
  },
  created() {
    setMeta({ title: "대시 보드", description: "메이드올" });
  },
  mounted() {
    this.getSearchRank();
  },
  methods: {
    async getSearchRank() {
      this.rankDataLoading = true;
      this.rankData = { list: [], item: [] };
      this.product = "";
      this.rankData = await this.$axios.get("smartstore/getRank");
      this.rankData = this.rankData.data;
      console.log(this.rankData);
      this.rankDataLoading = false;
    },
    async refreshSearch() {
      if (this.refreshSearchLoading) return;
      this.refreshSearchLoading = true;
      await this.$axios.patch("smartstore/searchRank");
      this.getSearchRank();
      this.refreshSearchLoading = false;
    },
    async addKeyWord(product) {
      this.addKeyWordDialog = true;
      this.product = product;
    },
    closeDialog() {
      this.addKeyWordDialog = false;
    },
    async addProduct() {
      console.log(this.addProductValue);
      this.addProductDialog = false;
      const saveData = {};
      this.rankData.list.push(this.addProductValue);
      saveData.list = this.rankData.list;
      saveData[this.addProductValue] = [{ keyWord: this.addProductValue }];
      await this.$axios.patch("smartstore/addProduct", saveData);
      this.refreshSearch();
    },
  },
};
</script>
