<template>
  <v-dialog v-model="dialog" width="320px" persistent>
    <v-card rounded="lg" class="py-2">
      <v-card-title>
        키워드 수정 <v-spacer />
        <v-btn icon @click="$emit('closeDialog')" color="onSurface">
          <feather type="x-circle" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="(item, index) in text" :key="item.keyWord" class="d-flex">
          <v-text-field
            v-model="text[index]"
            dense
            hide-details
            class="my-3 mr-3"
          />
          <v-btn
            icon
            small
            class="my-auto"
            color="red"
            @click="text.splice(index, 1)"
          >
            <feather type="minus-square" size="18" />
          </v-btn>
        </div>
        <v-btn
          class="mt-3"
          block
          color="transparent"
          depressed
          @click="text.push('')"
        >
          <feather type="plus-square" size="18" class="mr-2" /> 추가
        </v-btn>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-btn block color="primary" @click="edit()" :loading="saveBtnLoading">
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import _ from "lodash";
export default {
  props: { dialog: Boolean, data: Object, product: String },
  data() {
    return { saveBtnLoading: false, text: [] };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.text = [];
        for (var i = 0; i < this.data[this.product].length; i++) {
          this.text[i] = this.data[this.product][i].keyWord;
        }
      }
    },
  },
  methods: {
    async edit() {
      this.saveBtnLoading = true;
      const saveData = {};
      saveData[this.product] = [];
      for (var i = 0; i < this.text.length; i++) {
        saveData[this.product].push({ keyWord: this.text[i] });
      }
      try {
        await this.$axios.patch("smartstore/addKeyWord", saveData);
      } catch (e) {
        console.log(e);
      }
      this.$emit("refreshSearch");
      this.$emit("closeDialog");
      this.saveBtnLoading = false;
    },
  },
};
</script>

<style></style>
